import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Link } from "gatsby";
import Cursor from "../../components/cursor";
import style from "./designerLeague.module.css";

export default () => {
  let [move, setMove] = useState(0);
  const windowWidth =
    typeof window !== "undefined" ? document.documentElement.clientWidth : 0;
  const [arrowLeftImg, setArrowLeftImg] = useState(false);
  const [arrowRightImg, setArrowRightImg] = useState(true);

  function arrowLeft() {
    const wrapWidth =
      typeof window !== "undefined" ? $("#wrap").outerWidth() : 0;
    setArrowRightImg(true);
    if (move === wrapWidth) {
      setArrowLeftImg(false);
      setArrowRightImg(true);
    }

    if (move === 0) return;
    setMove((move -= wrapWidth));
    $("#wrap").animate({ scrollLeft: move }, 400);
  }

  function arrowRight() {
    const wrapWidth =
      typeof window !== "undefined" ? $("#wrap").outerWidth() : 0;
    setArrowLeftImg(true);
    if (move === wrapWidth) {
      setArrowRightImg(false);
      setArrowLeftImg(true);
    }
    if (move === wrapWidth * 2) return;
    setMove((move += wrapWidth));
    $("#wrap").animate({ scrollLeft: move }, 400);
  }

  useEffect(() => {
    if (windowWidth < 576) {
      $("#content").on("touchstart", function (e) {
        let mosePos = e.changedTouches[0].clientX;
        let startx = $("#wrap").scrollLeft();
        $(document).on("touchmove", function (e) {
          let left = e.changedTouches[0].clientX - mosePos;
          $("#wrap").scrollLeft(startx - left);
        });
        $(document).on("touchend", function () {
          $(this).unbind();
        });
      });
    } else {
      $("#content").on("mousedown", function (e) {
        let mosePos = e.clientX;
        let startx = $("#wrap").scrollLeft();
        $(document).on("mousemove", function (e) {
          let left = e.clientX - mosePos;
          $("#wrap").scrollLeft(startx - left);
        });
        $(document).on("mouseup", function () {
          $(this).unbind();
        });
      });
    }
  }, []);

  return (
    <div className={style.designerBox}>
      <Cursor></Cursor>
      <div className={style.designerHeader}>
        <Link to="/">
          <div className={style.icyIcon}></div>
        </Link>
        <div className={style.arrowGroup}>
          <img
            width="640"
            height="360"
            src={
              arrowLeftImg
                ? require("../../img/arrow_l_black.png")
                : require("../../img/arrow_l_grey.png")
            }
            alt="icy左箭头"
            onClick={arrowLeft}
          />
          <img
            width="640"
            height="360"
            src={
              arrowRightImg
                ? require("../../img/arrow_r_black.png")
                : require("../../img/arrow_r_grey.png")
            }
            alt="icy右箭头"
            onClick={arrowRight}
          />
        </div>
      </div>
      <div className={style.designerWrap} id="wrap">
        <div className={style.designer} id="content">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
